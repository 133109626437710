import React from "react";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import Image from "next/image";

import holiday from "./../../public/svgs/blog/holiday.svg";
import taxi from "./../../public/svgs/blog/taxi.svg";
import van from "./../../public/svgs/blog/van.svg";

const BlogLinks = () => {
  const { t } = useTranslation("home");

  return (
    <>
      {/* <!-- Process --> */}
      <section className="relative py-24 dark:bg-jacarta-800">
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="bansko to sofia, bansko to sofia airport, sofia airport to city centre, sofia airport to bansko"
            className="h-full w-full"
          />
        </picture>
        <div className="container">
          <h4 className="mb-10 text-center font-display text-3xl text-jacarta-700 dark:text-white">
            {t("blog-links.title")}
          </h4>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#10b981] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(16,185,129,.3)] dark:border-jacarta-700 dark:bg-jacarta-700">
              <div className="mb-6 inline-flex items-center justify-center">
                <div className="w-[75px] h-[75px] shrink-0">
                  <Image
                    src={van}
                    alt="from sofia to plovdiv, sofia to bucharest, sofia to bansko, sofia bansko taxi"
                  ></Image>
                </div>
                <h5 className="ml-3 font-display text-lg text-jacarta-700 dark:text-white">
                  {t("blog-links.blog-1.title")}
                </h5>
              </div>
              <p className="dark:text-jacarta-300">
                {t("blog-links.blog-1.description")}
              </p>
              <div className="mt-6 flex justify-end">
                <Link href="/blog/single_post/rent-a-car-alternatives-sofia">
                  <a
                    disabled
                    className="flex items-center justify-center underline  dark:text-white font-bold  transition-all"
                  >
                    {t("blog-links.read-more-btn")}
                  </a>
                </Link>
              </div>
            </div>
            <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#10b981] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(16,185,129,.3)] dark:border-jacarta-700 dark:bg-jacarta-700">
              <div className="mb-6 inline-flex items-center justify-center">
                <div className="w-[75px] h-[75px] shrink-0">
                  <Image
                    src={holiday}
                    alt="sofia airport to city, from sofia to plovdiv, day trips from sofia"
                  ></Image>
                </div>
                <h5 className="ml-3 font-display text-lg text-jacarta-700 dark:text-white">
                  {t("blog-links.blog-2.title")}
                </h5>
              </div>
              <p className="dark:text-jacarta-300">
                {t("blog-links.blog-2.description")}
              </p>
              <div className="mt-6 flex justify-end">
                <Link href="/blog/single_post/holidays-to-bulgaria-travel-guide">
                  <a className="flex items-center justify-center underline dark:text-white font-bold transition-all">
                    {t("blog-links.read-more-btn")}
                  </a>
                </Link>
              </div>
            </div>
            <div className="relative rounded-2.5xl border border-jacarta-100 bg-white p-10 shadow-[0_5px_0_0_#10b981] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(16,185,129,.3)] dark:border-jacarta-700 dark:bg-jacarta-700">
              <div className="mb-6 inline-flex items-center justify-center">
                <div className="w-[75px] h-[75px] shrink-0">
                  <Image
                    src={taxi}
                    alt="sofia to istanbul, sofia to bansko transfer, sofia to skopje, sofia to borovets transfer"
                  ></Image>
                </div>
                <h5 className="ml-3 font-display text-lg text-jacarta-700 dark:text-white">
                  {t("blog-links.blog-3.title")}
                </h5>
              </div>
              <p className="dark:text-jacarta-300">
                {t("blog-links.blog-3.description")}
              </p>
              <div className="mt-6 flex justify-end">
                <Link href="/blog">
                  <a className="flex items-center justify-center underline dark:text-white font-bold transition-all">
                    {t("blog-links.read-more-btn")}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex py-10 px-10 justify-center">
          <Link href="/blog">
            <a className="flex items-center justify-center w-[18rem] hover:bg-accent-lighter bg-accent-dark rounded-full py-3 px-1 text-center font-semibold text-white transition-all">
              {t("blog-links.blog-nav")}
            </a>
          </Link>
        </div>
      </section>
      {/* <!-- end process --> */}
    </>
  );
};

export default BlogLinks;
